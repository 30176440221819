import { useQuery } from '@tanstack/react-query';
import type { Dispatch, FC, SetStateAction } from 'react';
import { createContext, useState } from 'react';

import { getTasks } from '../api/taskEngine.api';
import type { UploadedFile } from '../hooks/useFileUpload';
import { Task } from '../taskEngine.models';

interface ITaskEngineContext {
  isLoading?: boolean;
  error?: unknown;
  tasks: Task[];
  files: {
    uploadedFiles: UploadedFile[];
    setUploadedFiles: Dispatch<SetStateAction<UploadedFile[]>>;
    resetUploadedFiles: () => void;
  };
}

export const TaskEngineContext = createContext<ITaskEngineContext>({
  tasks: [],
  files: {
    uploadedFiles: [],
    setUploadedFiles: () => {},
    resetUploadedFiles: () => {},
  },
});

export const TaskEngineProvider: FC = ({ children }) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const { data, error, isLoading } = useQuery(['tasks'], getTasks, {
    /**
     * We don't show a loader state on Task Engine,
     * so we need to turn off the retry mechanism for this query to fail early.
     */
    retry: false,
    initialData: [],
  });

  const resetUploadedFiles = () => setUploadedFiles?.([]);

  return (
    <TaskEngineContext.Provider
      value={{
        isLoading,
        error,
        tasks: data,
        files: {
          uploadedFiles,
          setUploadedFiles,
          resetUploadedFiles,
        },
      }}
    >
      {children}
    </TaskEngineContext.Provider>
  );
};
