import {
  PartialQuestionnaire,
  QuestionType,
  RoutesObject,
  Rule,
} from '@getpopsure/qnr-framework';

export type BuilderQuestionnaire = {
  components: PartialQuestionnaire<Record<string, unknown>, unknown, unknown>;
  routes: RoutesObject<Record<string, unknown>>;
  rules: Rule<Record<string, unknown>>[];
};

export type MODE = 'BUILD' | 'PREVIEW';

export const ADD_NEW_QUESTION = 'ADD_NEW_QUESTION';

export type AdditionalQuestionType = 'QUOTEPAGE' | 'REVIEW';
export type ExtendedQuestionType = QuestionType | AdditionalQuestionType;

export type Field =
  | { type: 'input'; hardCoded?: boolean }
  | { type: 'number' }
  | { type: 'text' }
  | { type: 'select'; options: string[] }
  | { type: 'object'; properties: Record<string | number, Field> }
  | { type: 'boolean' }
  | { type: 'list' }
  | {
      type: 'array';
      properties: Record<string | number, Field>;
      static?: boolean;
      rules?: {
        ifProperty: string | number;
        equals: string;
        hideProperty: string | number;
      }[];
    }
  | {
      type: 'imageSelect';
      imageType: 'verticalImage' | 'illustration';
    }
  | {
      type: 'radioOrCheckboxInput';
    };
