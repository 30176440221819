import {
  getRoutes,
  QuestionnaireQuestions,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import { UserWithBrokerMandate } from 'models/user';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { AppState } from 'reducers';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { DogLiabilitySpain, isDangerousBreed } from './models';
import {
  DogLiabilitySpainComponents,
  getTranslatedQuestionnaire,
} from './questionnaire';

const rules: Rule<DogLiabilitySpain>[] = [];

const removeReview: RemoveAnswerType<DogLiabilitySpain> = {
  op: 'always',
  questions: ['review'],
};

const removeAnswersLogic: Partial<
  Record<keyof DogLiabilitySpain, RemoveAnswerType<DogLiabilitySpain>>
> = {
  breed: {
    op: 'always',
    questions: ['quote', 'review'],
  },
  quote: removeReview,
  name: removeReview,
  email: removeReview,
  address: removeReview,
  microChipNumber: removeReview,
  dogName: removeReview,
  review: {
    op: 'always',
    questions: ['hasDownloadedDocs'],
  },
};

const prefillNameAndBirthDate = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<DogLiabilitySpain> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

export const SignupPage = () => {
  const questionnaireAnswers =
    useSelector(
      (state: AppState) => state.genericQuestionnaire.dogLiabilitySpain
    ) || {};
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);
  const { t } = useSafeTranslation();

  const onAnswer = <QuestionId extends keyof DogLiabilitySpain>(
    questionId: QuestionId,
    answer: DogLiabilitySpain[QuestionId]
  ) => {
    dispatch(
      storeGenericQuestionnaireAnswer('dogLiabilitySpain', {
        [questionId]: answer,
      })
    );

    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(
      removeGenericQuestionnaireAnswer('dogLiabilitySpain', answersToRemove)
    );

    const toUpdate = prefillNameAndBirthDate(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );
    if (toUpdate) {
      dispatch(storeGenericQuestionnaireAnswer('dogLiabilitySpain', toUpdate));
    }
  };

  const dangerousBreed = questionnaireAnswers.breed
    ? questionnaireAnswers.breed.some(isDangerousBreed)
    : false;

  const questions = getTranslatedQuestionnaire(t, dangerousBreed);

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, routes.policies.dogLiabilitySpain.path),
    rules,
  };

  return (
    <Switch>
      <Route path={routes.policies.dogLiabilitySpain.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: DogLiabilitySpainComponents,
          }}
          basePath={routes.policies.dogLiabilitySpain.path}
          questionId="intro"
          featureName="DogLiabilitySpain"
        />
      </Route>
    </Switch>
  );
};
