import { calendly } from '@getpopsure/private-constants';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Button, InformationBox } from '@popsure/dirty-swan';
import classNames from 'classnames';
import InfoBox from 'components/infoBox';
import Markdown from 'components/markdown';
import { questionPropLookup } from 'features/pension/lookups';
import { InvestmentSustainabilityMap, Pension } from 'features/pension/models';
import { FormEvent, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';
import { RadioOption } from 'shared/models/types';

import styles from './InvestmentSustainability.module.scss';

export const InvestmentSustainability = ({
  questionnaireAnswers,
  onSubmitValue,
}: QuestionnaireFormProps<Pension>) => {
  const { t } = useSafeTranslation();
  const { investmentSustainability } = questionPropLookup(t);
  const [radioValue, setRadioValue] = useState(
    questionnaireAnswers.investmentSustainability || ''
  );
  const [isValid, setIsValid] = useState<boolean | undefined>();

  const entries = Object.entries(InvestmentSustainabilityMap(t)) as [
    string,
    RadioOption
  ][];

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (radioValue === 'GREEN_INVESTING') {
      setIsValid(false);
    } else {
      setIsValid(true);
      onSubmitValue(radioValue);
    }
  };

  return (
    <form
      className={classNames(
        styles.container,
        'p-body d-flex fd-column ai-start jc-center'
      )}
      onSubmit={handleSubmit}
    >
      <h1 className="p-h1 mt16 mb8">{investmentSustainability.question}</h1>
      <p className="p-p mt8">{investmentSustainability.description}</p>
      <div className={classNames(styles.innerContainer, 'd-flex gap32')}>
        <div
          className={classNames(styles.labelContainer, 'p-label-container ws6')}
        >
          {entries.map(([v, label], index) => (
            <div className={index !== 0 ? 'mt8' : ''} key={v}>
              <input
                id={v}
                className="p-radio"
                type="radio"
                value={v}
                onChange={() => {
                  setRadioValue(v);
                  setIsValid(undefined);
                }}
                checked={radioValue === v}
              />
              <label
                htmlFor={v}
                className="p-label p-label--bordered"
                data-cy={`radio-${v}`}
              >
                {typeof label === 'string' ? (
                  label
                ) : (
                  <div>
                    <p className="p-p">{label.title}</p>
                    <span className="d-block p-p p-p--small tc-grey-600">
                      {label.description}
                    </span>
                  </div>
                )}
              </label>
            </div>
          ))}
          <AnimateHeight duration={300} height={isValid === false ? 'auto' : 0}>
            <InformationBox variant="warning" className="mt24 p-p wmx6">
              <Markdown openLinksInNewTab>
                {t(
                  'pension.qnr.investmentStrategy.errorMessage',
                  "We can't offer a 100% green product with low fees. If you're interested in a 100% green product, [please schedule a call]({{calendlyUrl}}).",
                  { calendlyUrl: calendly.pensionInsurance }
                )}
              </Markdown>
            </InformationBox>
          </AnimateHeight>
          <Button className="ws3 mt16" disabled={!radioValue}>
            {t('pension.qnr.investmentApproach.button.caption', 'Continue')}
          </Button>
        </div>
        <div className={classNames(styles.additionalInfo)}>
          <InfoBox
            title={investmentSustainability.additionalInfo?.title as string}
            description={investmentSustainability.additionalInfo?.description}
          />
        </div>
      </div>
    </form>
  );
};
