import { ClaimsAction } from 'constants/actions';
import { BikeClaims } from 'features/bikeClaims/models';
import { DentalClaims } from 'features/dentalClaims/models';
import { ExpatClaim } from 'features/expatClaims/models';
import { ExpatEuClaim } from 'features/expatEuClaims/models';
import { ExpatSpainClaim } from 'features/expatSpainClaims/models';
import { HouseholdClaims } from 'features/householdClaims/models';
import {
  LegalBookConsultation,
  LegalSubmitClaims,
} from 'features/legalClaims/models';
import { LegalSubmitClaims as LegalSubmitClaimsV2 } from 'features/legalClaimsV2/models';
import { LiabilityClaims } from 'features/liabilityClaims/models';
import { PrivateHealthClaims } from 'features/privateHealthClaims/models';
import { Claim, ClaimSubmittableInfo, LegalClaimInfo } from 'models/claims';
import { Reducer } from 'redux';

interface ClaimsState {
  claims?: Claim[];
  currentClaim?: Claim;
  submittableClaimInfo?: Partial<ClaimSubmittableInfo>;
  legalClaimInfo?: Partial<LegalClaimInfo>;
  liability?: Partial<LiabilityClaims>;
  dental?: Partial<DentalClaims>;
  household?: Partial<HouseholdClaims>;
  privateHealth?: Partial<PrivateHealthClaims>;
  legalSubmitClaimInfo?: Partial<LegalSubmitClaims>;
  legalSubmitClaimInfoV2?: Partial<LegalSubmitClaimsV2>;
  legalBookConsultation?: Partial<LegalBookConsultation>;
  bike?: Partial<BikeClaims>;
  expat?: Partial<ExpatClaim>;
  expatEu?: Partial<ExpatEuClaim>;
  expatSpain?: Partial<ExpatSpainClaim>;
}

export const defaultState = {};

const claims: Reducer<ClaimsState, ClaimsAction> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case 'GET_CLAIMS':
      return {
        ...state,
        claims: action.claims,
      };
    case 'GET_CURRENT_CLAIM':
      return {
        ...state,
        currentClaim: action.currentClaim,
      };
    case 'MERGE_CLAIM_DOCUMENT':
      if (!state?.currentClaim) return state;
      return {
        ...state,
        currentClaim: {
          ...state.currentClaim,
          documents: action.documents,
          internalStatus: action.internalStatus,
          status: action.status,
        },
      };
    case 'STORE_CLAIM_INFO':
      return {
        ...state,
        submittableClaimInfo: {
          ...state.submittableClaimInfo,
          ...action.submittableClaimInfo,
        },
      };
    case 'FLUSH_CLAIM_TYPE':
      return {
        ...state,
        submittableClaimInfo: { iban: state.submittableClaimInfo?.iban },
      };
    case 'STORE_LEGAL_CLAIM_INFO':
      return {
        ...state,
        legalClaimInfo: {
          ...state.legalClaimInfo,
          ...action.legalClaimInfo,
        },
      };
    case 'FLUSH_LEGAL_CLAIM_DETAILS':
      return {
        ...state,
        legalClaimInfo: {
          ...state.legalClaimInfo,
          detail: undefined,
        },
      };

    /* New claims flows
      All upcoming claims flow reducers should live in this pattern
    */
    case 'STORE_LIABILITY_CLAIM':
      return {
        ...state,
        liability: {
          ...state.liability,
          ...action.liability,
        },
      };
    case 'FLUSH_LIABILITY_CLAIM':
      return {
        ...state,
        liability: undefined,
      };

    case 'STORE_DENTAL_CLAIM':
      return {
        ...state,
        dental: {
          ...state.dental,
          ...action.dental,
        },
      };
    case 'FLUSH_DENTAL_CLAIM':
      return {
        ...state,
        dental: undefined,
      };

    case 'STORE_HOUSEHOLD_CLAIM':
      return {
        ...state,
        household: {
          ...state.household,
          ...action.household,
        },
      };
    case 'FLUSH_HOUSEHOLD_CLAIM':
      return {
        ...state,
        household: undefined,
      };

    case 'STORE_PRIVATE_HEALTH_CLAIM':
      return {
        ...state,
        privateHealth: {
          ...state.privateHealth,
          ...action.privateHealth,
        },
      };
    case 'FLUSH_PRIVATE_HEALTH_CLAIM':
      return {
        ...state,
        privateHealth: undefined,
      };

    case 'STORE_BIKE_CLAIM':
      return {
        ...state,
        bike: {
          ...state.bike,
          ...action.bike,
        },
      };

    case 'FLUSH_BIKE_CLAIM':
      return {
        ...state,
        bike: undefined,
      };

    case 'STORE_LEGAL_SUBMIT_CLAIM':
      return {
        ...state,
        legalSubmitClaimInfo: {
          ...state.legalSubmitClaimInfo,
          ...action.legalSubmitClaimInfo,
        },
      };
    case 'FLUSH_LEGAL_SUBMIT_CLAIM':
      return {
        ...state,
        legalSubmitClaimInfo: undefined,
      };
    case 'STORE_LEGAL_BOOK_CONSULTATION':
      return {
        ...state,
        legalBookConsultation: {
          ...state.legalBookConsultation,
          ...action.legalBookConsultation,
        },
      };
    case 'FLUSH_LEGAL_BOOK_CONSULTATION':
      return {
        ...state,
        legalBookConsultation: undefined,
      };

    case 'STORE_LEGAL_SUBMIT_CLAIM_V2':
      return {
        ...state,
        legalSubmitClaimInfoV2: {
          ...state.legalSubmitClaimInfoV2,
          ...action.legalSubmitClaimInfo,
        },
      };
    case 'FLUSH_LEGAL_SUBMIT_CLAIM_V2':
      return {
        ...state,
        legalSubmitClaimInfoV2: undefined,
      };

    case 'STORE_EXPAT_CLAIM':
      return {
        ...state,
        expat: {
          ...state.expat,
          ...action.expat,
        },
      };

    case 'FLUSH_EXPAT_CLAIM':
      return {
        ...state,
        expat: undefined,
      };

    case 'STORE_EXPAT_EU_CLAIM':
      return {
        ...state,
        expatEu: {
          ...state.expatEu,
          ...action.expatEu,
        },
      };

    case 'FLUSH_EXPAT_EU_CLAIM':
      return {
        ...state,
        expatEu: undefined,
      };

    case 'STORE_EXPAT_SPAIN_CLAIM':
      return {
        ...state,
        expatSpain: {
          ...state.expatSpain,
          ...action.expatSpain,
        },
      };

    case 'FLUSH_EXPAT_SPAIN_CLAIM':
      return {
        ...state,
        expatSpain: undefined,
      };

    default:
      return state;
  }
};

export default claims;
