import { trackBookACallClicked } from '@getpopsure/analytics';
import {
  Deductible,
  getExpatLTPrices,
} from '@getpopsure/expat-lt-pricing-engine';
import { calendly } from '@getpopsure/private-constants';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import dayjs from 'dayjs';
import {
  ExpatLongTerm,
  isValidRegion,
  QuotePlan,
} from 'features/expatLongTerm/models';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserId } from 'selectors/user';
import { isUnder18 } from 'shared/util/isUnder18';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import { QuoteView } from './Quote.view';

export const Quote = ({
  onSubmitValue,
  questionnaireAnswers,
  isDependent,
}: QuestionnaireFormProps<ExpatLongTerm> & { isDependent?: boolean }) => {
  const { dateOfBirth, region } = questionnaireAnswers;
  const userId = useSelector(getUserId);
  const [selectedPlan, setSelectedPlan] = useState<QuotePlan>('PREMIUM');
  const [deductible, setDeductible] = useState<Deductible>('0');
  const [extendedCoverage, setExtendedCoverage] = useState(false);
  const coveredArea = extendedCoverage ? 'AREA_TWO' : 'AREA_ONE';
  const isUnderEighteen = isUnder18(dateOfBirth);

  // if not dependent and under 18 should show prices for 18-25 years old
  // (manually defined dateOfBirth19YearsOld to mock the 18-25 years scope)
  const dateOfBirth19YearsOld = dayjs()
    .subtract(19, 'year')
    .format('YYYY-MM-DD');
  const quoteDateOfBirth =
    !isDependent && isUnderEighteen ? dateOfBirth19YearsOld : dateOfBirth ?? '';

  const quoteOptions = getExpatLTPrices({
    dateOfBirth: quoteDateOfBirth,
    coveredArea,
    deductible,
    germanRates: region === 'de',
  });

  if (!region || !isValidRegion(region)) {
    return null;
  }

  const handleBookACall = () => {
    trackBookACallClicked({
      user_id: userId ?? null,
      insurance_type: 'INCOMING_LT',
      region,
      medium: 'quote_screen',
    });
    window.Calendly.initPopupWidget({ url: calendly.expatInsuranceEu });
  };

  const cellReplacements = {
    'table_row$062a6b78-3e8a-4459-a498-ba74b69910de_0': {
      onClick: () => setSelectedPlan('BASIC'),
      isSelected: selectedPlan === 'BASIC',
      disabled: false,
    },
    'table_row$062a6b78-3e8a-4459-a498-ba74b69910de_1': {
      onClick: () => setSelectedPlan('PREMIUM'),
      isSelected: selectedPlan === 'PREMIUM',
      disabled: false,
    },
    'table_row$c0843b08-11a4-4cf6-b2b6-2a9c23f3d1e9_0': {
      text: englishFormattedEuroCurrency(quoteOptions.BASIC),
    },
    'table_row$c0843b08-11a4-4cf6-b2b6-2a9c23f3d1e9_1': {
      text: englishFormattedEuroCurrency(quoteOptions.PREMIUM),
    },
  };

  const handleOnSelectionChanged = (index: number) => {
    setSelectedPlan(index === 0 ? 'BASIC' : 'PREMIUM');
  };

  return (
    <QuoteView
      cellReplacements={cellReplacements}
      hasExtendedCoverage={extendedCoverage}
      isDependent={isDependent}
      isUnderEighteen={isUnderEighteen}
      onBookACall={handleBookACall}
      onExtendedCoverageChange={setExtendedCoverage}
      onSubmit={() =>
        onSubmitValue({
          plan: selectedPlan,
          dateOfBirth,
          deductible,
          coveredArea,
          germanRates: region === 'de',
        })
      }
      onDeductibleChange={setDeductible}
      onSelectionChanged={handleOnSelectionChanged}
      plan={selectedPlan}
      deductible={deductible}
      price={quoteOptions[selectedPlan]}
    />
  );
};
