import { Button, PlusIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import DelinquencyBlocker from 'components/DelinquencyBlocker';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { StatusBadge } from 'features/claimsV2/components/statusBadge';
import { getClaimTypeMapping } from 'features/claimsV2/utils';
import {
  claimStatusesClassMapping,
  getClaimStatusesTextMapping,
} from 'models/claims';
import { InsuranceStatus } from 'models/insurances/status';
import { InsuranceTypes } from 'models/insurances/types';
import { getTitleMapping } from 'models/insurances/types/mapping';
import { PolicyClaim } from 'models/policies';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { isDelinquentUser } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';

import { PolicySingleCard } from '../../components/PolicySingleCard';
import { DATE_FORMAT } from '../../constants';
import noClaimsIcon from './icons/no-claims.svg';
import styles from './PolicyClaims.module.scss';

const isActive = (
  policyStatus: InsuranceStatus,
  insuranceType: InsuranceTypes,
  activeUntil?: string
) => {
  if (
    policyStatus === 'CANCELED' &&
    !['EXPAT_V2', 'INCOMING'].includes(insuranceType)
  ) {
    return activeUntil ? dayjs().isBefore(activeUntil) : true;
  }

  if (
    policyStatus === 'CANCELED' &&
    ['EXPAT_V2', 'INCOMING'].includes(insuranceType)
  ) {
    return activeUntil
      ? dayjs().isBefore(dayjs(activeUntil).add(2, 'year'))
      : true;
  }

  if (policyStatus === 'ACTIVE' || policyStatus === 'COVERED') {
    return true;
  }

  return false;
};

const showDelinquentBlocker = (insuranceType: InsuranceTypes) => {
  return insuranceType !== 'PRIVATE_HEALTH';
};

const ITEM_LIMIT = 3;

export type PolicyClaimsProps = {
  claims: PolicyClaim[];
  route: string;
  insuranceType: InsuranceTypes;
  activeUntil?: string;
  policyStatus: InsuranceStatus;
  screen?: {
    button?: {
      dataCy?: string;
      title?: string;
    };
  };
};

export const PolicyClaims = ({
  route,
  claims,
  insuranceType,
  activeUntil,
  policyStatus,
  screen,
}: PolicyClaimsProps) => {
  const { t } = useSafeTranslation();
  const [itemsLimit, setItemsLimit] = useState(ITEM_LIMIT);
  const [delinquencyBlockerOpen, setDelinquencyBlockerOpen] = useState(false);

  const showMore = () => {
    setItemsLimit((limit) => Math.min(limit + ITEM_LIMIT, claims.length));
  };

  const history = useHistory();
  const isDelinquent = useSelector(isDelinquentUser);

  if (!claims) return null;

  const sortedClaims = claims
    .sort((a, b) => {
      if (a.status === 'ACTION_NEEDED') {
        return -1;
      }
      if (b.status === 'ACTION_NEEDED') {
        return 1;
      }
      return dayjs(b.claimDate).diff(dayjs(a.claimDate));
    })
    .slice(0, itemsLimit);

  return (
    <div className="p-container">
      <DelinquencyBlocker
        onClose={() => setDelinquencyBlockerOpen(false)}
        isOpen={delinquencyBlockerOpen}
        description={t(
          'account.delinquencyBlocker.claims.description',
          'You can submit claims once the outstanding insurance premiums are successfully collected.'
        )}
      />
      <PolicySingleCard
        title={t('myPolicies.policyClaims.title', 'Recent claims')}
        action={
          isActive(policyStatus, insuranceType, activeUntil) ? (
            <Button
              data-cy={screen?.button?.dataCy}
              className={styles.button}
              leftIcon={<PlusIcon />}
              variant="filledGray"
              onClick={() => {
                if (isDelinquent && showDelinquentBlocker(insuranceType)) {
                  setDelinquencyBlockerOpen(true);
                } else {
                  history.push(route, { from: 'policy' });
                }
              }}
            >
              {screen?.button?.title ||
                t('claims.createLinkButton.title', 'New claim')}
            </Button>
          ) : null
        }
      >
        {sortedClaims.length === 0 ? (
          <div className="w100 d-flex fd-column ai-center jc-center br8">
            <img src={noClaimsIcon} alt="feathers" />
            <div className="p-p tc-grey-600 mt32">
              {t(
                'myPolicies.policyClaims.noClaimsSubmitted.message',
                'No claims submitted.'
              )}
            </div>
          </div>
        ) : (
          <>
            {sortedClaims.map(({ id, claimTypes, claimDate, status }) => {
              const hasClaimTypes = claimTypes && claimTypes.length > 0;
              const claimTypeMapping = hasClaimTypes
                ? getClaimTypeMapping(t, {
                    insuranceType,
                    claimType: claimTypes[0].claimType,
                  })
                : null;

              return (
                <ClaimItem
                  key={id}
                  path={generatePath(routes.claims.detail.path, {
                    claimId: id,
                  })}
                >
                  <div className="w-100">
                    <div className="p-p fw-bold tc-grey-700">
                      {claimTypeMapping ?? (
                        <>
                          {getTitleMapping(t)[insuranceType]}{' '}
                          {t(
                            'myPolicies.policyClaims.claimType.fallback',
                            'claim'
                          )}
                        </>
                      )}
                    </div>
                    <div
                      className={classNames(
                        'p-p--small tc-grey-700 fw-bold mt8',
                        styles.mobileHidden
                      )}
                    >
                      {dayjs(claimDate).format(DATE_FORMAT)}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'jc-between ai-center',
                      styles.mobileHidden
                    )}
                  >
                    <StatusBadge
                      badgeColor={claimStatusesClassMapping[status]}
                      title={getClaimStatusesTextMapping(t)[status]}
                    />
                  </div>
                  <div className={classNames('f-nowrap', styles.mobileDisplay)}>
                    <div className="d-flex jc-between ai-center mt16">
                      <StatusBadge
                        badgeColor={claimStatusesClassMapping[status]}
                        title={getClaimStatusesTextMapping(t)[status]}
                      />
                      <div className="p-p--small tc-grey-700 fw-bold">
                        {dayjs(claimDate).format(DATE_FORMAT)}
                      </div>
                    </div>
                  </div>
                </ClaimItem>
              );
            })}
            {itemsLimit < (claims?.length || 0) && (
              <div className="d-flex ai-center jc-center mt16 mb16">
                <button
                  className="p-p tc-grey-500 fw-700 ds-interactive-component ds-card--no-dropshadow"
                  onClick={showMore}
                  type="button"
                >
                  {t(
                    'myPolicies.policyClaims.showMoreButton.title',
                    'Show more'
                  )}
                </button>
              </div>
            )}
          </>
        )}
      </PolicySingleCard>
    </div>
  );
};

const ClaimItem = ({
  children,
  path,
}: {
  children: React.ReactNode;
  path: string;
}) => {
  return (
    <Link
      className={classNames(
        'w-100 p24 mb16 d-flex ai-center f-nowrap jc-between br8 ds-card--actionable ds-card--no-dropshadow bs-xs',
        styles.claimItem
      )}
      to={path}
    >
      {children}
    </Link>
  );
};
