import { TFunction } from '@getpopsure/i18n-react';
import { calendly, faq } from '@getpopsure/private-constants';
import { breeds } from '@getpopsure/public-models';
import { images } from '@popsure/dirty-swan';
// TODO: add specific icon for DogLiabilitySpain.
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';

import { createPostQuote } from './actions';
import { PolicyDetails } from './components/policyDetails';
import { DogLiabilitySpain, DogLiabilitySpainGroupIds } from './models';

export const DogLiabilitySpainComponents = {} as const;

export type DogLiabilitySpainQuestionnaire = SignupQuestionnaireType<
  DogLiabilitySpain,
  DogLiabilitySpainGroupIds,
  typeof DogLiabilitySpainComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  isDangerousBreed = false
): DogLiabilitySpainQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'INTRO',
    props: {
      title: t(
        'dogLiabilitySpain.qnr.preQuote.intro.title',
        "Hey! Let's get you a quote in minutes"
      ),

      subTitle: t(
        'dogLiabilitySpain.qnr.preQuote.intro.subTitle',
        'We need a few pieces of information about you and we can figure out the rest.'
      ),
    },
    screen: {
      continueButtonText: t(
        'dogLiabilitySpain.qnr.preQuote.intro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'breed',
    required: true,
    type: 'AUTOSUGGEST_MULTI',
    props: {
      options: [...breeds],
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.preQuote.mixedBreed.title',
        'What breed or mix/crossbreed is your dog?'
      ),
      skipDetails: {
        title: "It's not listed",
        skipQuestion: { value: [] },
        path: '',
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    required: true,
    type: 'QUOTEPAGE',
    groupId: 'signup',
    props: {
      config: {
        title: 'Here is your quote',
        headerImage: {
          src: imageTypeMapping.DOG_LIABILITY_SPAIN,
          alt: 'dog',
        },
        reviewBadge: true,
        coveredSection: {
          title: "What's covered?",
          items: [
            {
              title: 'Medical injuries to other people',
              description:
                'If your dog gets excited and runs into someone, causing them to fall and hurt themselves, your policy will cover the expenses.',
              icon: {
                src: images.aid,
                alt: 'Aid',
              },
            },
            {
              title: 'Damage to rented apartments',
              description:
                'Did your dog decide to chew up your rented furniture? Relax and keep your security deposit when you move out!',
              icon: {
                src: images.bed,
                alt: 'Bed',
              },
            },
            {
              title: 'Property damage',
              description:
                "You'll be covered if your dog damages someone's belongings (E,g, your friends carpet or laptop)",
              icon: {
                src: images.damagedLaptop,
                alt: 'Damaged laptop',
              },
            },
            {
              title: 'Financial loss',
              description:
                "If your dog bites the mailperson and can't work after that, dog liability insurance will cover you.",
              icon: {
                src: images.moneyIncome,
                alt: 'Money Income',
              },
            },
          ],
        },
        priceHeader: {
          title: '{{value}}',
          subTitle: 'per month',
        },
        actions: {
          items: [
            { type: 'SubHeader', text: 'Area of coverage' },
            {
              type: 'Description',
              text: 'Depending on the area of coverage you select your dog is covered in all EU countries or just in Spain. We recommend EU-wide coverage if you take your dog to other countries.',
            },
            {
              type: 'Toggle',
              mapValues: {
                0: 'EU-wide',
                1: 'Only Spain',
              },
            },
          ],
          valuesMap: {
            0: isDangerousBreed ? '13.23' : '6.62',
            1: isDangerousBreed ? '8.82' : '4.41',
          },
          submitMap: [
            {
              id: '1',
              price: isDangerousBreed ? 13.23 : 6.62,
              region: 'EU-wide',
            },
            {
              id: '2',
              price: isDangerousBreed ? 8.82 : 4.41,
              region: 'Spain',
            },
          ],
          button: {
            text: 'Get covered',
          },
        },
        notCoveredSection: {
          title: "What's not covered?",
          items: [
            {
              title: 'Damage to your own property',
              description:
                'Replacement or repair of things you own is not covered by dog liability.',
              icon: {
                src: images.brokenGlass,
                alt: '',
              },
            },
            {
              title: 'Rented electronics',
              description:
                "If your dog damages an electronic device you rented from someone else, it's not covered.",
              icon: {
                src: images.washingMachine,
                alt: '',
              },
            },
            {
              title: 'Broken glass in your own home',
              description:
                "If you're renting a flat, glass breakage isn't covered. You can get [household insurance]() to cover glass.",
              icon: {
                src: images.brokenAquarium,
                alt: 'Broken glass',
              },
            },
            {
              title: 'Dangerous breeds',
              description:
                "Breeds that might be considered to be dangerous can't be covered. [Check the complete list]().",
              icon: {
                src: images.bigDog,
                alt: '',
              },
            },
          ],
        },
        ctaBox: {
          header: 'Dog liability insurance',
          title: '{{value}}',
          subTitle: 'per month',
          buttonText: 'Get covered',
        },
        faq: {
          title: 'Frequently asked questions',
          data: [
            {
              questionId: 1,
              question:
                'My dog is considered to be a “dangerous” breed can I still be covered?',
              answer:
                'Some dog breeds which are considered “dangerous” can’t be covered through Feather but other insurers may still cover them. Policies for such dog breeds are typically a bit more expensive.',
            },
            {
              questionId: 2,
              question:
                'Do I need dog liability insurance if I already have personal liability insurance?',
              answer:
                'Personal liability insurance covers small animals like cats or birds but dogs usually aren’t covered. This is why we recommend getting dog liability insurance in addition to personal liability insurance.',
            },
            {
              questionId: 3,
              question: 'What happens if my dog has puppies?',
              answer:
                'Your dog’s puppies are automatically covered by dog liability insurance until they turn 1 year old or when they permanently leave your household, whichever happens sooner. ',
            },
            {
              questionId: 4,
              question: 'Can I get dog insurance for a mixed-breed dog?',
              answer:
                'Yes! Our dog liability insurance includes mixed-breed dogs, as well as pedigree and cross-breed dogs. Unfortunately, we cannot cover (whether pedigree, cross-breed, or mixed breed) some breeds that are considered to be dangerous can not be covered.',
            },
            {
              questionId: 5,
              question: 'Is dog liability insurance mandatory?',
              answer:
                'Yes. It’s mandatory in some regions of Germany (Berlin, Niedersachsen, Hamburg, Schleswig-Holstein, Thüringen, Sachsen-Anhalt) as well as other countries.',
            },
            {
              questionId: 6,
              question:
                'Does dog liability insurance pay if I leave my dog with someone else?',
              answer:
                'Your dog is also covered when watched by someone else such as your spouse, children up to the age of 18, or partner (living at your address). When your dog is watched by a non-professional dog sitter it is also covered. However, when hiring a professional dog sitter the dog sitter needs to have liability insurance for this.',
            },
            {
              questionId: 7,
              question: 'How am I covered outside of Germany?',
              answer:
                'Dog liability insurance coverage is valid worldwide. If you permanently move away from Germany you can keep your dog liability insurance coverage if you want. ',
            },
            {
              questionId: 8,
              question: 'Is my dog still covered when walking without a leash?',
              answer:
                'Yes. Your dog is covered when walking without a leash. Your dog is even covered when walking without a leash in places where leashes are mandatory. ',
            },
          ],
          footer: `See our [FAQ page](${faq.base}) for more answers.`,
        },
        moreQuestionsSection: {
          bookACallGACategory: 'Dog',
          title: 'Have more questions?',
          bookACallUrl: calendly.dogLiabilityInsurance,
          secondaryActionLink: faq.base,
          secondaryAction: 'Visit our FAQ',
        },
      },
    },
    screen: {
      layout: 'Standalone',
    },
  },
  {
    id: 'signupIntro',
    required: true,
    type: 'INTRO',
    props: {
      title: t(
        'dogLiabilitySpain.qnr.signup.signupIntro.title',
        "Great! Let's sign you up"
      ),
      subTitle: t(
        'dogLiabilitySpain.qnr.signup.signupIntro.subTitle',
        'Just 6 questions, it takes about 1-2 minutes.'
      ),
    },
    screen: {
      continueButtonText: t(
        'dogLiabilitySpain.qnr.signup.signupIntro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'email',
    required: true,
    type: 'EMAIL',
    props: {
      verticalId: 'dogLiabilitySpain',
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.postalCode.email.title',
        "What's your email address?"
      ),
      description: t(
        'dogLiabilitySpain.qnr.signup.postalCode.email.description',
        'This policy will be accessible in your Feather account.'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.name.title',
        "What's your name?"
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'address',
    required: true,
    type: 'ADDRESS',
    props: {
      countryCode: 'ES',
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.address.title',
        "What's your address in Spain?"
      ),
      noMaxWidth: true,
    },
    groupId: 'signup',
  },
  {
    id: 'dateOfBirth',
    type: 'DATE',
    required: true,
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'subtract', type: 'years', value: 0 },
      },
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.preQuote.dateOfBirth.title',
        'What’s your date of birth?'
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'day',
          value: 1,
        },
        msg: {
          type: 'Error',
          value: t(
            'dogLiabilitySpain.qnr.preQuote.dateOfBirth.errorMessage',
            'You can’t select a birth date in the future. Please make sure the date of birth is correct.'
          ),
        },
      },
    ],
    groupId: 'signup',
  },
  {
    id: 'dogName',
    required: true,
    type: 'INPUT',
    props: {
      placeholder: t(
        'dogLiabilitySpain.qnr.signup.dogName.placeholder',
        "Dog's name"
      ),
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.dogName.title',
        "What's your dog's name?"
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'microChipNumber',
    required: true,
    type: 'INPUT',
    props: {},
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.microChipNumber.title',
        "What's your dog's microchip number?"
      ),
      additionalInfo: {
        title: t(
          'dogLiabilitySpain.qnr.signup.microChipNumber.additionalInfo.title',
          "What's a micro chip number?"
        ),
        description: t(
          'dogLiabilitySpain.qnr.signup.microChipNumber.additionalInfo.description',
          'El chip, también llamado código alfanumérico de transpondedor, es un número de identificación exclusivo para tu perro. Podrás encontrarlo en la cartilla o pasaporte del perro. También, acudiendo a tu veterinario podrás conocer si el perro tiene o no microchip y, en el caso de que así sea, le pasarán un lector especial para poder leer los datos que lleva insertados.'
        ),
      },
      skipDetails: {
        title: t(
          'bikeV2.qnr.signup.frameNumber.skipDetails.title',
          'Provide later'
        ),
        skipQuestion: { value: '' },
      },
    },
    groupId: 'signup',
  },
  {
    id: 'startDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 0 },
        max: { op: 'add', type: 'years', value: 5 },
      },
    },
    screen: {
      question: t(
        'dogLiabilitySpain.qnr.signup.startDate.title',
        'When would you like your coverage to start?'
      ),
    },
    validations: [
      {
        op: 'DateIsInFuture',
        msg: {
          type: 'Error',
          value: t(
            'dogLiabilitySpain.qnr.signup.DateIsInFuture.error',
            'Earliest start date is {{value}}.'
          ),
        },
      },
    ],
    groupId: 'preQuote',
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    // TODO: Types of property 'gaEventInsurance' are incompatible:
    // Add the correct GAEventInsuranceType and remove the ts-ignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    props: {
      requestType: 'REVIEW_SUBMIT_INFO',
      reviewValues: [
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.isMixedBreed',
            'Is mixed breed'
          ),
          value: { type: 'String', key: 'isMixedBreed' },
          path: 'isMixedBreed',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.pureBreed',
            'Dog breed'
          ),
          value: { type: 'String', key: 'pureBreed' },
          path: 'pureBreed',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.mixedBreed',
            'Mix/crossbreed'
          ),
          value: { type: 'String', key: 'mixedBreed' },
          path: 'mixedBreed',
        },
        {
          title: t('dogLiabilitySpain.qnr.signup.review.email', 'Email'),
          value: { type: 'String', key: 'email' },
          path: 'email',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.policyHolderName',
            "Policy holder's name"
          ),
          value: {
            type: 'Strings',
            key: 'name',
            keys: ['firstName', 'lastName'],
          },
          path: 'name',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.address',
            'Address in Spain'
          ),
          value: {
            type: 'Strings',
            key: 'address',
            keys: ['street', 'houseNumber', 'postcode', 'city'],
          },
          path: 'address',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.dateOfBirth',
            'Date of birth'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'dateOfBirth' },
          path: 'dateOfBirth',
        },
        {
          title: t('dogLiabilitySpain.qnr.signup.review.dogName', 'Dog Name'),
          value: { type: 'String', key: 'dogName' },
          path: 'dogName',
        },
        {
          title: t(
            'dogLiabilitySpain.qnr.signup.review.microChipNumber',
            'Micro chip number'
          ),
          value: {
            type: 'String',
            key: 'microChipNumber',
          },
          path: 'microChipNumber',
        },
        {
          title: 'Coverage start date',
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'startDate' },
          path: 'startDate',
        },
      ],
      verticalId: 'dogLiabilitySpain',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gaEventInsurance: '[Add GAEventInsuranceType]',
      createPostQuote,
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'downloadDocuments',
    required: true,
    type: 'DOWNLOAD',
    props: {
      // TODO: add the correct verticalId in src/components/DownloadPolicy/models/downloadPolicyModels.ts
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      verticalId: 'DOG_LIABILITY_SPAIN',
      checkList: [
        {
          id: 'confirm',
          description:
            'I agree to the terms and conditions and acknowledge I have received and understood the provided documentation.',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'reviewCheckout',
    required: true,
    type: 'CHECKOUT',
    props: {
      type: 'DOG_LIABILITY_SPAIN',
      PolicyDetails,
      checkoutIcon: imageTypeMapping.DOG_LIABILITY,
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
];
