import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint, { Client } from 'shared/api';
import { CreateQuoteDispatch } from 'SignupQuestionnaire/actions/account';

import {
  CreateQuoteReturnType,
  Quote,
  SubmittableDogLiabilitySpainQuestionnaire,
  ZQuote,
  ZSubmittableDogLiabilitySpainQuestionnaire,
} from './models';

const createDogLiabilitySpainQuote = async (
  _network: Client,
  _quote: Quote
) => {
  throw new Error('createDogLiabilitySpainQuote not implemented yet!');
};

const submitDogLiabilitySpainQuoteInformation = async (
  _network: Client,
  _quote: SubmittableDogLiabilitySpainQuestionnaire
) => {
  throw new Error(
    'submitDogLiabilitySpainQuoteInformation not implemented yet!'
  );
};

export const createPostQuote =
  (): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'dogLiabilitySpain'> | RequestAction
  > =>
  async (
    dispatch: CreateQuoteDispatch<'dogLiabilitySpain'>,
    getState: () => AppState
  ) => {
    const questionnaire = getGenericQuestionnaire(getState()).dog || {};

    try {
      // @TODO use zod to validate the quote shape
      if (!questionnaire.quote) {
        throw new Error(`[DOG_LIABILITY_SPAIN] Quote not defined`);
      }

      const quoteValidation = ZQuote.safeParse(questionnaire.quote);

      if (!quoteValidation.success) {
        throw new Error(
          `[DOG_LIABILITY_SPAIN - Submission] Questionnaire quote errors: ${quoteValidation.error.toString()}`
        );
      }

      const validatedQuote = quoteValidation.data;

      // TODO: define the data we want to create the questionnaire with
      const data = {};

      const validationResult =
        ZSubmittableDogLiabilitySpainQuestionnaire.safeParse(data);

      if (!validationResult.success) {
        throw new Error(
          `[DOG_LIABILITY_SPAIN - Submission] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedAnswers = validationResult.data;

      // TODO: implement `createDogLiabilitySpainQuotes` in `src/shared/api/index.ts`
      const {
        data: { id: quoteId },
      } = await createDogLiabilitySpainQuote(endpoint, validatedQuote);

      dispatch(setRequestInProcess(true, 'CREATE_POST_QUOTE_SUBMIT_INFO'));

      const {
        email,
        name: { firstName, lastName },
      } = validatedAnswers;

      // check if the account exists and create a new account if needed
      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            email,
          })
        );
      }

      const {
        data: { id: questionnaireId },
      } = await submitDogLiabilitySpainQuoteInformation(
        endpoint,
        validatedAnswers
      );
      dispatch(
        storeGenericQuestionnaireAnswer('dogLiabilitySpain', {
          quoteId,
          questionnaireId,
          quote: { ...questionnaire.quote, id: quoteId },
        })
      );
      dispatch(setRequestInProcess(false, 'CREATE_POST_QUOTE_SUBMIT_INFO'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          'CREATE_POST_QUOTE_SUBMIT_INFO'
        )
      );
      Sentry.captureException(
        `[DOG_LIABILITY_SPAIN Review] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };
