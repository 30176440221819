import classNames from 'classnames';
import dayjs from 'dayjs';
import documentBoxIcon from 'features/claimsV2/icons/document-box.svg';
import { getClaimTypeMapping } from 'features/claimsV2/utils';
import {
  ClaimStatuses,
  claimStatusesClassMapping,
  ClaimType,
  getClaimStatusesTextMapping,
} from 'models/claims';
import { InsuranceTypes } from 'models/insurances/types';
import {
  getTitleMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';
import { useSafeTranslation } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';

import { StatusBadge } from '../statusBadge';
import styles from './ClaimCard.module.scss';

interface Props {
  date: string;
  className?: string;
  status: ClaimStatuses;
  subTitle?: string;
  insuranceType: InsuranceTypes;
  claimTypes?: ClaimType[];
  nonActionable?: boolean;
  onClick?: () => void;
}

const noop = () => {};

export const ClaimCard = ({
  date,
  status,
  className = '',
  subTitle,
  insuranceType,
  claimTypes,
  nonActionable = false,
  onClick = noop,
}: Props) => {
  const { t } = useSafeTranslation();
  const onSelect = nonActionable ? () => {} : onClick;
  const hasClaimTypes = claimTypes && claimTypes.length > 0;
  const claimTypeMapping = hasClaimTypes
    ? getClaimTypeMapping(t, {
        insuranceType,
        claimType: claimTypes[0].claimType,
      })
    : null;

  return (
    <div
      className={classNames(
        'p24 d-flex ai-center f-nowrap jc-between bg-white br8 ds-card--no-dropshadow',
        styles.container,
        className,
        {
          'ds-card--actionable': !nonActionable,
          [styles.containerNoHover]: isMobileApp,
        }
      )}
      onClick={onSelect}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onSelect();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="d-flex ai-center">
        <img
          className={`mr16 br8 ${styles.icon}`}
          src={imageTypeMapping[insuranceType] ?? documentBoxIcon}
          alt="insurance icon"
        />
        <div
          className={classNames(
            'd-flex fd-column jc-between',
            styles.infoContainer
          )}
        >
          <div className={styles.info}>
            <div className="p-h4 tc-grey-700 mr16">
              {hasClaimTypes && claimTypeMapping ? (
                <>
                  {claimTypeMapping}
                  {claimTypes.length > 1 && <> +{claimTypes.length - 1}</>}
                </>
              ) : (
                <>
                  {getTitleMapping(t)[insuranceType]}{' '}
                  {t('claims.claimsCard.title.post.claim', 'claim')}
                </>
              )}
            </div>
          </div>
          <div className={classNames(styles.info, styles.mobileHidden)}>
            <div className="p-p--small tc-grey-700 fw-bold mr16">
              {dayjs(date).format('DD MMM YYYY')}
            </div>
            {subTitle && (
              <div className="p-p--small tc-grey-500">{subTitle}</div>
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames('jc-between ai-center', styles.badgeContainer)}
      >
        <StatusBadge
          badgeColor={claimStatusesClassMapping[status]}
          title={getClaimStatusesTextMapping(t)[status]}
        />
      </div>
      <div className={classNames('f-nowrap', styles.mobileDisplay)}>
        <div className={styles.info}>
          {subTitle && (
            <div className="p-p--small tc-grey-500 mt8">{subTitle}</div>
          )}
        </div>
        <div className="d-flex jc-between ai-center mt16">
          <StatusBadge
            badgeColor={claimStatusesClassMapping[status]}
            title={getClaimStatusesTextMapping(t)[status]}
          />
          <div className="p-p--small tc-grey-700 fw-bold mr16">
            {dayjs(date).format('DD MMM YYYY')}
          </div>
        </div>
      </div>
    </div>
  );
};
