import { TFunction } from '@getpopsure/i18n-react';
import {
  TableHeader,
  TableRowHeader,
  TableTrueFalse,
} from '@popsure/dirty-swan';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
  CellModal,
  DentalQuotePlan,
  QuoteTableData,
  QuoteTableModal,
  ValueWithDescription,
} from '../../../../models';
import { CTACell, ValueCell } from '../../TableCell';
import { ToothReplacementModal } from '../../ToothReplacementModal';

dayjs.extend(utc);

export const getTableHeaders = (
  t: TFunction,
  setModal: (modal: QuoteTableModal) => void,
  showPrice: boolean,
  planMapping: DentalQuotePlan
) => {
  const renderValueCell = (input: ValueWithDescription & CellModal) => (
    <ValueCell input={input} />
  );

  const renderTrueFalse = (input: boolean) => <TableTrueFalse value={input} />;

  const headers: Array<TableHeader<QuoteTableData>> = [
    {
      id: 1,
      label: '',
      default: true,
      cells: [
        {
          key: 'name',
          label: t(
            'dental.qnr.preQuote.quote.coverageTable.title',
            'Coverage overview'
          ),
          render: (value) => (
            <CTACell
              value={value}
              showPrice={showPrice}
              planMapping={planMapping}
            />
          ),
        },
        {
          key: 'waitingPeriod',
          label: (
            <TableRowHeader
              label={t(
                'dental.qnr.preQuote.quote.coverageTable.waitingPeriod.title',
                'No waiting periods'
              )}
              onClickInfo={() =>
                setModal({
                  wide: true,
                  title: t(
                    'dental.qnr.preQuote.quote.coverageTable.waitingPeriod.modal.title',
                    'Waiting period'
                  ),
                  children: t(
                    'dental.qnr.preQuote.quote.coverageTable.waitingPeriod.modal.description',
                    'There are no waiting periods; you can use your insurance immediately after your policy starts.\n\n Just keep in mind that any procedures for which you had an appointment or planned before applying for dental insurance won’t be covered.'
                  ),
                })
              }
            />
          ),
          render: renderTrueFalse,
        },
        {
          key: 'dentalCleaning',
          label: (
            <TableRowHeader
              label={t(
                'dental.qnr.preQuote.quote.coverageTable.dentalCleaning.title',
                'Professional dental cleaning'
              )}
              onClickInfo={() =>
                setModal({
                  wide: true,
                  title: t(
                    'dental.qnr.preQuote.quote.coverageTable.dentalCleaning.modal.title',
                    'Dental cleanings'
                  ),
                  children: t(
                    'dental.qnr.preQuote.quote.coverageTable.dentalCleaning.modal.description',
                    '**How often do I need dental cleanings?**\n\nMany dentists recommend having a dental cleaning every six months.\n\n**How much does it cost?**\n\nThe cost of a standard dental cleaning in Germany typically ranges from €60 to €120.'
                  ),
                })
              }
            />
          ),
          render: renderValueCell,
        },
        {
          key: 'dentalTreatments',
          label: (
            <TableRowHeader
              label={t(
                'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.title',
                'Dental treatments'
              )}
              subtitle={t(
                'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.subtitle',
                'E.g. high quality fillings, root canal treatments, mouth guards, pain relief treatments'
              )}
            />
          ),
          render: renderValueCell,
        },
        {
          key: 'toothReplacement',
          label: (
            <TableRowHeader
              label={t(
                'dental.qnr.preQuote.quote.coverageTable.toothReplacement.title',
                'Tooth replacement'
              )}
              subtitle={t(
                'dental.qnr.preQuote.quote.coverageTable.toothReplacement.subtitle',
                'E.g. implants, bridges, crowns, inlays'
              )}
              onClickInfo={() =>
                setModal({
                  wide: true,
                  title: t(
                    'dental.qnr.preQuote.quote.coverageTable.toothReplacement.modal.title',
                    'Tooth replacement'
                  ),
                  children: t(
                    'dental.qnr.preQuote.quote.coverageTable.toothReplacement.modal.description',
                    '**When do I need a tooth replacement?**\n\nThe most common reason is significant damage or loss of one or more teeth, which can’t be treated by fillings.  This can result from factors such as decay, gum disease, trauma, or injury.\n\n**What are the treatments covered under tooth replacement procedures?**\n\n- Crowns\n- Implants\n- Dental bridges\n- Inlays and onlays\n\n**How much does it cost?**\n\nTooth replacement procedures can be quite expensive, ranging from €1,000 for a crown to €3,000 for an implant.'
                  ),
                })
              }
            />
          ),
          render: renderValueCell,
        },
        {
          key: 'toothJawAlignment',
          label: (
            <TableRowHeader
              label={t(
                'dental.qnr.preQuote.quote.coverageTable.toothJawAlignment.title',
                'Tooth & jaw alignment'
              )}
              subtitle={t(
                'dental.qnr.preQuote.quote.coverageTable.toothJawAlignment.subtitle',
                'E.g. braces, aligners'
              )}
            />
          ),
          render: renderValueCell,
        },
        {
          key: 'teethWhitening',
          label: (
            <TableRowHeader
              label={t(
                'dental.qnr.preQuote.quote.coverageTable.teethWhitening.title',
                'Teeth whitening'
              )}
            />
          ),
          render: renderValueCell,
        },
        {
          key: 'cosmeticProcedures',
          label: (
            <TableRowHeader
              label={t(
                'dental.qnr.preQuote.quote.coverageTable.cosmeticProcedures.title',
                'Purely cosmetic procedures'
              )}
            />
          ),
          render: renderTrueFalse,
        },
      ],
    },
  ];
  return headers;
};

export const getTableData = (t: TFunction): Array<QuoteTableData> => {
  const isAfterSeptember2014 = !dayjs
    .utc(new Date())
    .isBefore(dayjs.utc('2024-09-01'), 'day');
  return [
    {
      id: 0,
      name: 'BASIC',
      waitingPeriod: true,
      dentalCleaning: {
        value: t(
          'dental.qnr.preQuote.quote.coverageTable.dentalCleaning.basic.value',
          '€150/year'
        ),
      },
      dentalTreatments: {
        value: true,
        description: t(
          'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.basic.description',
          'limits apply'
        ),
        cellModal: {
          wide: true,
          title: t(
            'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.basic.modal.title',
            'Combined treatment limits'
          ),
          children: t(
            'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.basic.modal.description',
            'Combined limit (total) for dental treatments:\n\n**First year**: €150\n\n**Second year**: €150\n\n**After 2 years**: No limits\n\n**Do limits carry over?**\n\nYes, if you submit a €50 claim and get reimbursed in the first year, the remaining €100 will carry over to the second year, giving you a limit of €250 for the second year.\n\n **What type of treatments fall within these limits?**\n\n- High quality fillings\n\n- Root canal and periodontitis treatments\n- Mouthguard/bite guards\n- Pain relief treatments\n\nThese limits do not apply in case of reconstructive work after an accident.'
          ),
        },
      },
      toothReplacement: {
        value: t(
          'dental.qnr.preQuote.quote.coverageTable.toothReplacement.basic.value',
          'up to 60%'
        ),
        description: t(
          'dental.qnr.preQuote.quote.coverageTable.toothReplacement.basic.description',
          'minimal care'
        ),
        cellModal: {
          wide: true,
          title: t(
            'dental.qnr.preQuote.quote.coverageTable.toothReplacement.basic.modal.title',
            'Tooth replacement coverage'
          ),
          children: t(
            'dental.qnr.preQuote.quote.coverageTable.toothReplacement.basic.modal.description',
            'The basic plan only includes the base-care-level coverage provided by public health insurance. It does not offer extra coverage for tooth replacement procedures.'
          ),
        },
      },
      toothJawAlignment: {
        value: true,
        description: t(
          'dental.qnr.preQuote.quote.coverageTable.toothJawAlignment.basic.description',
          'age limits apply'
        ),
        cellModal: {
          wide: true,
          title: t(
            'dental.qnr.preQuote.quote.coverageTable.toothJawAlignment.basic.modal.title',
            'Tooth and jaw alignment'
          ),
          children: t(
            'dental.qnr.preQuote.quote.coverageTable.toothJawAlignment.basic.modal.description',
            'Dental insurance - both basic and advanced plans - adds up to €2,000 to public health coverage for treatments starting before turning 21.\n\n**Limits**\n\n**First year**: €150\n\n**Second year**: €150\n\n**After 2 years**: €1,700\n\n**Do limits carry over?**\n\nYes, if you get reimbursed for €150 in the first year and do not use any funds in the second year, the remaining €150 will be carried over, giving you a total limit of €1,850 for jaw alignment treatments after 2 years.\n\nThese limits do not apply in case of reconstructive work after an accident.'
          ),
        },
      },
      teethWhitening: {
        value: false,
      },
      cosmeticProcedures: false,
    },
    {
      id: 1,
      name: 'ADVANCED',
      waitingPeriod: true,
      dentalCleaning: {
        value: isAfterSeptember2014
          ? t(
              'dental.qnr.preQuote.quote.coverageTable.dentalCleaning.advanced.value',
              '€200/year'
            )
          : true,
        description: isAfterSeptember2014
          ? t(
              'dental.qnr.preQuote.quote.coverageTable.dentalCleaning.advanced.limits.description',
              '20% co-pay applies'
            )
          : t(
              'dental.qnr.preQuote.quote.coverageTable.dentalCleaning.advanced.noLimits.description',
              'no limits'
            ),
        ...(isAfterSeptember2014 && {
          cellModal: {
            wide: true,
            title: t(
              'dental.qnr.preQuote.quote.coverageTable.dentalCleaning.advanced.modal.title',
              'Professional dental cleaning'
            ),
            children: t(
              'dental.qnr.preQuote.quote.coverageTable.dentalCleaning.advanced.modal.description',
              '**How does it work?**\n\nIf you submit a dental cleaning claim for €120, you will cover €24, and we will cover the remaining €96. You can submit as many dental cleanings per year as needed, up to a total reimbursement limit of €200.'
            ),
          },
        }),
      },
      dentalTreatments: {
        value: true,
        description: isAfterSeptember2014
          ? t(
              'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.advanced.limits.description',
              'limits apply'
            )
          : t(
              'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.advanced.noLimits.description',
              'no limits'
            ),
        ...(isAfterSeptember2014 && {
          cellModal: {
            wide: true,
            title: t(
              'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.advanced.modal.title',
              'Combined treatment limits'
            ),
            children: t(
              'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.advanced.modal.description',
              'Combined limit (total) for dental treatments:\n\n**First year**: €150\n\n**Second year**: €150\n\n**After 2 years**: No limits\n\n**Do limits carry over?**\n\nYes, if you submit a €50 claim and get reimbursed in the first year, the remaining €100 will carry over to the second year, giving you a limit of €250 for the second year.\n\n **What type of treatments fall within these limits?**\n\n- High quality fillings\n\n- Root canal and periodontitis treatments\n- Mouthguard/bite guards\n- Pain relief treatments\n\nThese limits do not apply in case of reconstructive work after an accident.'
            ),
          },
        }),
      },
      toothReplacement: {
        value: t(
          'dental.qnr.preQuote.quote.coverageTable.toothReplacement.advanced.value',
          'up to 100%'
        ),
        description: t(
          'dental.qnr.preQuote.quote.coverageTable.toothReplacement.advanced.description',
          'limits apply'
        ),
        cellModal: {
          wide: true,
          title: t(
            'dental.qnr.preQuote.quote.coverageTable.toothReplacement.advanced.modal.title',
            'Tooth replacement coverage'
          ),
          children: <ToothReplacementModal />,
        },
      },
      toothJawAlignment: {
        value: true,
        description: t(
          'dental.qnr.preQuote.quote.coverageTable.toothJawAlignment.advanced.description',
          'age limits apply'
        ),
        cellModal: {
          wide: true,
          title: t(
            'dental.qnr.preQuote.quote.coverageTable.toothJawAlignment.advanced.modal.title',
            'Tooth and jaw alignment'
          ),
          children: t(
            'dental.qnr.preQuote.quote.coverageTable.toothReplacement.advanced.modal.description',
            'Dental insurance - both basic and advanced plans - adds up to €2,000 to public health coverage for treatments starting before turning 21.\n\n**Limits**\n\n**First year**: €150\n\n**Second year**: €150\n\n**After 2 years**: €1,700\n\n**Do limits carry over?**\n\nYes, if you get reimbursed for €150 in the first year and do not use any funds in the second year, the remaining €150 will be carried over, giving you a total limit of €1,850 for jaw alignment treatments after 2 years.\n\nThese limits do not apply in case of reconstructive work after an accident.'
          ),
        },
      },
      teethWhitening: {
        value: t(
          'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.advanced.value',
          '€200'
        ),
        description: t(
          'dental.qnr.preQuote.quote.coverageTable.teethWhitening.advanced.description',
          'every 2 years'
        ),
      },
      cosmeticProcedures: false,
    },
    {
      id: 2,
      name: 'Public health',
      waitingPeriod: true,
      dentalCleaning: {
        value: t(
          'dental.qnr.preQuote.quote.coverageTable.dentalCleaning.public.value',
          'up to €50/year'
        ),
        description: t(
          'dental.qnr.preQuote.quote.coverageTable.dentalCleaning.public.description',
          'varies by provider'
        ),
      },
      dentalTreatments: {
        value: t(
          'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.public.description',
          'Only minimal care'
        ),
        cellModal: {
          wide: true,
          title: t(
            'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.public.modal.title',
            'Dental treatments'
          ),
          children: t(
            'dental.qnr.preQuote.quote.coverageTable.dentalTreatments.public.modal.description',
            'Public health insurance only provides basic care-level (Grundvorsorge) treatments.\n\n- **Fillings**: coverage only for amalgam fillings, which has a metallic silver color. High-quality composite fillings are not covered.\n- **Root canal treatments**: covered if it is medically necessary and can preserve the tooth.\n- **Pain relief treatments**'
          ),
        },
      },
      toothReplacement: {
        value: t(
          'dental.qnr.preQuote.quote.coverageTable.toothReplacement.public.value',
          'up to 60%'
        ),
        description: t(
          'dental.qnr.preQuote.quote.coverageTable.toothReplacement.public.description',
          'minimal care'
        ),
        cellModal: {
          wide: true,
          title: t(
            'dental.qnr.preQuote.quote.coverageTable.toothReplacement.public.modal.title',
            'Tooth replacement coverage'
          ),
          children: t(
            'dental.qnr.preQuote.quote.coverageTable.toothReplacement.public.modal.description',
            "Public health insurance covers up to 60% of basic care-level (Grundvorsorge) treatments for tooth replacements. This includes amalgam, which has a metallic silver color and may cause sensitivity to hot and cold.\n\nIf you choose composite replacements that last longer and match your tooth color, they won't be covered by public health insurance."
          ),
        },
      },
      toothJawAlignment: {
        value: true,
        description: t(
          'dental.qnr.preQuote.quote.coverageTable.toothJawAlignment.public.description',
          'age limits apply'
        ),
        cellModal: {
          wide: true,
          title: t(
            'dental.qnr.preQuote.quote.coverageTable.toothJawAlignment.public.modal.title',
            'Tooth and jaw alignment'
          ),
          children: t(
            'dental.qnr.preQuote.quote.coverageTable.toothJawAlignment.public.modal.description',
            'Public health insurance covers medically necessary treatments with severity level 3 (KIG 3) or higher for individuals under 18 years old.\n\n**How does the severity level determined?**\n\nThe orthodontist assesses the medical necessity of the treatment using 5 severity levels, known as orthodontic indication groups (KIG).'
          ),
        },
      },
      teethWhitening: {
        value: false,
      },
      cosmeticProcedures: false,
    },
  ];
};
