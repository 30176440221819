import {
  Address,
  Breeds,
  dangerousBreedsSpain,
} from '@getpopsure/public-models';
import { CheckoutInfo } from 'features/checkout/models';
import { Name } from 'models/user';
import { ActionResponse } from 'shared/models/types';
import { ZAddressSchema, ZNameSchema } from 'shared/models/validations';
import { SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

// TODO: define the quote type
export const ZQuote = z.object({
  id: z.string(),
});

export type Quote = z.TypeOf<typeof ZQuote>;

// TODO: define the checkout policy details
export type CheckoutPolicyDetails = unknown;

// TODO: define the required  quote schema
export type CreateQuoteReturnType = {
  status: ActionResponse;
};

export type DogLiabilitySpain = {
  intro: SCREEN;
  breed?: Breeds[];
  postalCode: number;
  isDangerousBreed: boolean;
  quote: Quote;

  signupIntro: SCREEN;
  name: Name;
  email: string;
  address: Address;
  dateOfBirth: string;
  dogName: string;
  startDate: string;
  microChipNumber: string;

  review: boolean;
  hasDownloadedDocs: boolean;
  downloadDocuments: boolean;

  checkoutInfo: CheckoutInfo<CheckoutPolicyDetails>;
  reviewCheckout: boolean;
  checkout: boolean;

  quoteId: string;
  questionnaireId: string;
};

export const ZSubmittableDogLiabilitySpainQuestionnaire = z.object({
  name: ZNameSchema,
  email: z.string(),
  address: ZAddressSchema,
  startDate: z.string(),
});
export type SubmittableDogLiabilitySpainQuestionnaire = z.infer<
  typeof ZSubmittableDogLiabilitySpainQuestionnaire
>;

export type DogLiabilitySpainGroupIds = 'preQuote' | 'signup';

export const isDangerousBreed = (breed: Breeds) => {
  return dangerousBreedsSpain.some(
    (dangerousBreed) =>
      dangerousBreed.toLocaleLowerCase() === breed.toLocaleLowerCase()
  );
};
